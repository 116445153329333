<template>
  <page>
    <p class="
        bg-red-700
        text-red-100
        flex
        items-center
        justify-center
        rounded
        p-2
      ">
      <icon face="mdiAlertCircleOutline" />
      {{ $t('media.allUsers') }}
    </p>
    <div class="flex flex-wrap items-start justify-center">
      <div class="max-w-xs text-center p-4" v-for="hero in heroItems" :key="hero.name">
        <h1 class="text-5xl font-bold uppercase" :class="{
          'text-red-700': !isBusy && hero.value[0] > 0,
          'text-green-700': !isBusy && hero.value[0] === 0,
          'text-blue-200': isBusy
        }">
          {{ isBusy ? '...' : hero.value[0] }}
        </h1>
        <h2 class="text-lg">
          {{ isBusy ? '...' : $t('media.trashSize', hero.value) }}
        </h2>
        <div class="text-center mt-2 mb-4" v-if="hero.value[0] > 0">
          <action variant="danger" @click="onConfirm(hero.name)">{{
            $t('media.delCta')
            }}</action>
        </div>
        <p class="text-xs" :class="{
          'opacity-20': isBusy
        }">
          {{ $t(hero.label) }}
        </p>
      </div>
    </div>
    <template v-if="orphanedMedia.length > 0">
      <div class="mt-4">
        <media-grid has-override :override="orphanedMedia" :selected="selectedItems" pick="model"
          @select="onSelectImage"></media-grid>
      </div>
      <div class="mt-4 flex items-center justify-center">
        <action class="mx-2" variant="danger" :disabled="orphanedMediaSelected[0] === 0" @click="onConfirm('selected')">
          {{ $t('media.orphanedCta', orphanedMediaSelected) }}</action>
      </div>
    </template>
    <div class="mt-8"></div>
    <modal v-if="confirm" :title="$t('media.delConfirmTitle')" @close="confirm = null">
      <p>{{ $t('media.delConfirmText', confirm) }}</p>
      <div class="flex justify-between mt-4">
        <action variant="danger" :disabled="isDeleting" :loading="isDeleting" @click="doConfirmed()">{{
          $t('actions.yesSure') }}
        </action>
        <action variant="success" :disabled="isDeleting" @click="confirm = null">{{ $t('actions.no') }}
        </action>
      </div>
    </modal>
  </page>
</template>

<script>
import { getNiceSize } from '@/services'
import MediaGrid from '@/components/media/MediaGrid'

export default {
  components: { MediaGrid },
  data() {
    return {
      results: {},
      temp: [],
      isBusy: false,
      isDeleting: false,
      checkAll: false,
      checkTemp: false,
      selectedItems: [],
      confirm: null
    }
  },
  mounted() {
    this.loadMediaTrash()
  },
  beforeDestroy() {
    clearTimeout(this.debouncedSearchTk)
  },
  computed: {
    invalidMedia() {
      const temp = this.results?.invalidMedia ?? []
      const size = temp.reduce((a, t) => a + t.size, 0)
      const nice = getNiceSize({ size }, 2)
      return [temp.length, `${nice.value}${nice.unit === 'b' ? '' : nice.unit}`]
    },
    orphanedMediaSelected() {
      const size = this.orphanedMedia
        .filter(({ _id }) => this.selectedItems.includes(_id))
        .reduce((sum, entry) => sum + (entry?._stored ?? 0), 0)
      const nice = getNiceSize({ size }, 2)
      return [
        this.selectedItems.length,
        `${nice.value}${nice.unit === 'b' ? '' : nice.unit}`
      ]
    },
    orphanedMediaTotal() {
      const size = this.orphanedMedia.reduce(
        (sum, entry) => sum + (entry?._stored ?? 0),
        0
      )
      const nice = getNiceSize({ size }, 2)
      return [
        this.orphanedMedia.length,
        `${nice.value}${nice.unit === 'b' ? '' : nice.unit}`
      ]
    },
    orphanedMedia() {
      return this.results?.orphanedMedia ?? []
    },
    tempStorage() {
      const temp = this.results?.temporaryFiles ?? []
      const size = temp.reduce((a, t) => a + t.size, 0)
      const nice = getNiceSize({ size }, 2)
      return [temp.length, `${nice.value}${nice.unit === 'b' ? '' : nice.unit}`]
    },
    orphanStorage() {
      const temp = this.results?.orphanedStored ?? []
      const size = temp.reduce((a, t) => a + t.size, 0)
      const nice = getNiceSize({ size }, 2)
      return [temp.length, `${nice.value}${nice.unit === 'b' ? '' : nice.unit}`]
    },
    heroItems() {
      return [
        { name: 'temp', label: 'media.tempText', value: this.tempStorage },
        {
          name: 'stored',
          label: 'media.storageText',
          value: this.orphanStorage
        },
        {
          name: 'invalid',
          label: 'media.invalidText',
          value: this.invalidMedia
        },
        {
          name: 'orphaned',
          label: 'media.orphanedText',
          value: this.orphanedMediaTotal
        }
      ]
    }
  },
  methods: {
    onConfirm(what) {
      switch (what) {
        case 'temp':
          this.confirm = [...this.tempStorage, what]
          break
        case 'invalid':
          this.confirm = [...this.invalidMedia, what]
          break
        case 'orphaned':
          this.confirm = [...this.orphanedMediaTotal, what]
          break
        case 'stored':
          this.confirm = [...this.orphanStorage, what]
          break
        case 'selected':
          this.confirm = [...this.orphanedMediaSelected, what]
          break
      }
    },
    async loadMediaTrash() {
      this.isBusy = true
      try {
        const response = await this.$http.get(`/trash`)
        this.results = response.data
      } catch (e) {
        console.error('Error while loading trash', e)
        this.results = {}
      } finally {
        this.isBusy = false
      }
    },
    onSelectImage(ids) {
      this.selectedItems = ids
    },
    async doConfirmed() {
      if (!this.confirm?.[2]) {
        return
      }
      const what = this.confirm[2]
      switch (what) {
        case 'temp':
        case 'orphaned':
        case 'stored':
        case 'invalid':
          await this.doConfirmedBulk(what)
          break
        case 'selected':
          await this.doConfirmedSelected()
          break
        default:
          console.error('Do not know what to do with', what)
      }
      this.confirm = null
    },
    async doConfirmedBulk(action) {
      this.isDeleting = true
      try {
        await this.$http.put(`/trash/${action}`)
        await this.loadMediaTrash()
      } catch (err) {
        console.error('Error while bulk trash', err)
      } finally {
        this.isDeleting = false
      }
    },
    async doConfirmedSelected() {
      if (!this.selectedItems?.length) {
        return
      }
      this.isDeleting = true
      try {
        await this.$http.put(`/trash/selected`, {
          selected: this.selectedItems
        })
        await this.loadMediaTrash()
      } catch (err) {
        console.error('Error while selected trash', err)
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>
